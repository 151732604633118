<template>
    <BT-Dialog
        v-if="mode == null"
        @ok="selectMode"
        hideActions
        hideButton
        :item="{ mode: null }"
        show
        width="500">
        <template v-slot:body="{ item, ok }">
            <div>
                <v-btn
                    @click="() => { item.mode = 'management'; ok(); }"
                    height="100"
                    width="100%">
                    Management
                </v-btn>
                <v-btn
                    @click="() => { item.mode = '3pl'; ok(); }"
                    height="100"
                    width="100%">
                    3PL Management
                </v-btn>
                <v-btn
                    @click="() => { item.mode = 'procurement'; ok(); }"
                    height="100"
                    width="100%">
                    Procurement
                </v-btn>
            </div>
        </template>
    </BT-Dialog>
    <BT-Blade-Items
        v-else-if="locationSelected"
        bladeName="stock-balancer"
        :bladesData="bladesData"
        canBreakdown
        :canClose="false"
        :canMinimize="false"
        :canPin="false"
        flexColumn
        hideHeader
        navigation="products"
        :refreshToggle="refreshToggle"
        :onPullSuccessAsync="refreshSources"
        :params="productParams"
        startRefresh
        title="Stock Balancer"
        :actualUsedHeight="144">
        <template v-slot:toolbar>
            <v-toolbar-title>
                <BT-Entity
                    alternateText="All Locations"
                    navigation="locations"
                    ignorePermissions
                    :itemValue="locationID"
                    itemText="locationName">
                    <template v-slot:prepend><span>Stock Balancer: </span></template>
                </BT-Entity>
            </v-toolbar-title>
        </template>
        <template v-slot:toolbar-right>
            <v-tabs v-model="stepperState" style="width: 200px;">
                <v-tab>Balancer</v-tab>
                <v-tab v-if="locationID != null">{{ newTransfers.length }} {{ newTransfers.length === 1 ? 'Transfer' : 'Transfers' }}</v-tab>
                <v-tab v-if="is3pl">{{ new3plTransfers.length }} {{ new3plTransfers.length === 1 ? '3PL Transfer' : '3PL Transfers' }}</v-tab>
                <v-tab v-if="mode == 'management'">{{ newBatches.length }} {{ newBatches.length === 1 ? 'Batch' : 'Batches' }}</v-tab>
                <v-tab v-if="mode == 'procurement'">{{ newPurchaseOrders.length }} {{ newPurchaseOrders.length == 1 ? 'Purchase Order' : 'Purchase Orders' }}</v-tab>
            </v-tabs>
        </template>
        <template v-slot:body="{ items }">
            <div>
                <BT-Dialog
                    hideButton
                    :getOnOpen="item => { return item }"
                    :item="currentTransferOptions"
                    :openToggle="openTransferToggle"
                    @ok="transfer"
                    width="500">
                    <template slot-scope="{ item }">
                        <v-switch
                            v-model="item.isTo"
                            :label="item.isTo ? 'Transfer To' : 'Transfer From'" />

                        <BT-Select
                            navigation="locations"
                            v-model="item.locationID"
                            :label="item.isTo ? 'Destination' : 'Location'"
                            itemText="locationName"
                            itemValue="id"
                            :onFilter="filterTransferLocations" />

                        <v-text-field
                            v-model.number="item.quantity"
                            label="Quantity"
                            type="number" />
                    </template>
                </BT-Dialog>

                <BT-Dialog
                    :getOnOpen="item => { return item }"
                    hideButton
                    :item="currentPurchaseOptions"
                    label="Purchase"
                    :openToggle="openPurchaseToggle"
                    @ok="purchase"
                    width="500">
                    <template v-slot="{ item }">
                        <BT-Field-Select
                            v-model="item.locationID"
                            navigation="locations"
                            itemText="locationName"
                            label="Destination"
                            isEditing />
                        <v-list>
                            <v-subheader>Items</v-subheader>
                            <template v-for="(t, ind) in item.orderItems">
                                <Order-Line-Item
                                    canEdit
                                    :dense="false"
                                    :item="t"
                                    :key="ind"
                                    :showPricing="false"
                                    showSupplier />
                                <v-divider :key="'d' + t.triggeringProductID" />
                            </template>
                        </v-list>
                    </template>
                </BT-Dialog>

                <!-- sidebar showing current assignments -->
                <BT-Sidebar-Items
                    customURL="/get/ByProduct"
                    :dividers="false"
                    inline
                    :label="(currentBalanceItem != null ? currentBalanceItem.product.abbreviation : '') + ' - On Order'"
                    navigation="stock-consignments"
                    navWidth="400"
                    :onPullSuccessAsync="list => { return pullAssignments(list, currentBalanceItem) }"
                    :params="consignmentParams"
                    :refreshToggle="consignmentToggle"
                    :searchProps="['buyer.companyName', 'consignmentNumber']"
                    :show.sync="showConsignments"
                    :showButton="false"
                    small>
                    <template v-slot:top="{ allItems }">
                        <v-list-item>
                            <v-list-item-content>Total</v-list-item-content>
                            <v-list-item-subtitle>
                                <v-row class="ma-0 pa-0">
                                    On Order: {{ allItems.sum(z => z.orderQuantity) | toDisplayNumber}}
                                    <v-spacer />
                                    Assigned: {{ allItems.sum(z => z.stockQuantity) | toDisplayNumber}}
                                </v-row>
                            </v-list-item-subtitle>
                        </v-list-item>
                    </template>
                    <template v-slot:listItem="listItemData">
                        <v-list-item>
                            <v-list-item-action>
                                #{{ listItemData.item.consignmentNumber }}
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ listItemData.item.buyer.companyName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ listItemData.item.dueDate | toDayShortDate }}</v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <v-row class="ma-0">
                                        On Order: {{ listItemData.item.orderQuantity | toDisplayNumber }}
                                        <v-spacer />
                                        Assigned: {{ listItemData.item.stockQuantity | toDisplayNumber }}
                                    </v-row>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text>
                                    <v-btn small icon :to="{ name: 'stock-consignment', params: { id: listItemData.item.id } }">
                                        <v-icon small>mdi-open-in-new</v-icon>
                                    </v-btn>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </BT-Sidebar-Items>
                <BT-Sidebar-Items
                    v-if="locationID != null"
                    :dividers="false"
                    inline
                    :label="(currentBalanceItem != null ? currentBalanceItem.product.abbreviation : '') + ' - Stock On Hand'"
                    navigation="stock-items"
                    :params="batchParams"
                    :refreshToggle="batchToggle"
                    :searchProps="['buyer.companyName', 'consignmentNumber']"
                    :show.sync="showBatches"
                    :showButton="false"
                    small>
                    <template v-slot:listItem="listItemData">
                        <v-list-item>
                            <v-list-item-action>
                                {{ listItemData.item.available | toDisplayNumber }}
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ listItemData.item.batch.batchcode }}</v-list-item-title>
                                <v-list-item-subtitle>
                                    <v-row class="ma-0">
                                        DOM: {{ listItemData.item.batch.dom | toShortDate }}
                                        <v-spacer />
                                        EXP: {{ listItemData.item.batch.exp | toShortDate }}
                                    </v-row>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text>
                                    <v-btn small icon :to="{ name: 'stock-consignment', params: { id: listItemData.item.id } }">
                                        <v-icon small>mdi-open-in-new</v-icon>
                                    </v-btn>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </BT-Sidebar-Items>
                <v-tabs-items v-model="stepperState">
                    <v-tab-item>
                        <BT-Blade-Items
                            @select="selectBalanceItem"
                            :items="items"
                            canBreakdown
                            :canClose="false"
                            :canRefresh="false"
                            :canSearch="false"
                            canSearchLocal
                            :canSelect="false"
                            hideBladeHeader
                            hideActions
                            :headers="headers"
                            :actualUsedHeight="144">
                            <template v-slot:actions>
                                <v-divider vertical class="mx-2" />

                                <BT-Date-Picker
                                    hideDetails
                                    label="DOM"
                                    singleLine
                                    small
                                    v-model="defaultDOM" />

                                <BT-Stock-Balance-Overview
                                    :balanceItem="currentBalanceItem"
                                    :locationID="locationID"
                                    :openToggle="showOverviewToggle"
                                    :transfers="transfers"
                                    :trackedItems="trackedItems"
                                    :batches="batches" />

                                <BT-Stock-Status-Overview
                                    v-if="currentBalanceItem != null"
                                    :openToggle="showStatusToggle"
                                    :productID="currentBalanceItem.productID" />

                            </template>
                            <template v-slot:actionsRight="{ allItems }">
                                <v-btn @click="toggleStrategy(allItems)" small class="primary">
                                    <v-icon v-if="balancerStrat == 'Topup'" small left>mdi-package-up</v-icon>
                                    <v-icon v-else-if="balancerStrat == 'Trigger'" small left>mdi-gauge-low</v-icon>
                                    {{ balancerStrat }}
                                </v-btn>
                            </template>
                            <template slot="product.productName" slot-scope="{ item }">
                                <v-slide-x-transition>
                                    <v-icon
                                        v-if="item.displayBalance >= 0"
                                        color="success"
                                        left
                                        small>mdi-check</v-icon>
                                    <v-icon
                                        v-else
                                        color="warning"
                                        left
                                        small>mdi-alert</v-icon>
                                </v-slide-x-transition>
                                {{ item.product.productName }}
                            </template>
                            <template v-slot:onHand="{ item }">
                                <span v-if="item.onHand != 0">{{ item.onHand | toDisplayNumberSigned }}</span>
                            </template>
                            <template v-slot:onOrder="{ item }">
                                <span v-if="item.onOrder != 0">{{ 0 - item.onOrder | toDisplayNumberSigned }}</span>
                            </template>
                            <template v-slot:incoming="{ item }">
                                <span v-if="(item.incoming + item.purchasing + item.toMake) != 0">{{ item.incoming + item.purchasing + item.toMake | toDisplayNumberSigned }}</span>
                            </template>
                            <template v-slot:threshold="{ item }">
                                <span 
                                    v-if="balancerStrat == 'Topup' && item.topUpAdjustment > 0" 
                                    :title="item.balance + ' of ' + item.lowerThreshold + ' to ' + item.upperThreshold">{{ item.topUpAdjustment | toDisplayNumberSigned }}</span>
                                <span 
                                    v-else-if="balancerStrat == 'Trigger' && item.triggerAdjustment > 0" 
                                    :title="item.balance + ' of ' + item.lowerThreshold + ' to ' + item.upperThreshold">{{ item.triggerAdjustment | toDisplayNumberSigned }}</span>
                                <!-- <span v-if="item.threshold != 0" class="text--disabled">{{ item.threshold | toDisplayNumberSigned }}</span> -->
                            </template>
                            <template v-slot:percent="{ item }">
                                <v-slide-x-reverse-transition>
                                    <div v-if="item.upperThreshold > 0 && !Number.isNaN(item.percent) && Number.isFinite(item.percent)">
                                        <v-progress-circular
                                            size="30"
                                            :title="item.balance + ' of ' + item.lowerThreshold + ' to ' + item.upperThreshold"
                                            :value="item.percent"
                                            class="mr-2">
                                            <span style="font-size: 9px;">{{ item.percent }}%</span>
                                        </v-progress-circular>
                                    </div>
                                </v-slide-x-reverse-transition>
                            </template>
                            <template v-slot:displayBalance="{ item }">
                                <span :class="item.displayBalance >= 0 ? '' : 'error--text'">{{ item.displayBalance | toDisplayNumberSigned }}</span>
                            </template>

                            <template v-slot:options="{ item }">
                                <v-row dense class="mx-0 px-0">
                                    <v-btn
                                        v-if="is3pl && item.balance < 0"
                                        icon
                                        small
                                        title="Request From Client"
                                        @click.stop="requestStock(item)">
                                        <v-icon small>mdi-arrow-decision</v-icon>
                                    </v-btn>
                                    <v-edit-dialog
                                        v-else-if="is3pl"
                                        large
                                        saveText="Add"
                                        @save="requestStock(item, requestQTY)">
                                        <v-icon small style="width: 24px; height: 24px;">mdi-arrow-decision</v-icon>
                                        <template v-slot:input>
                                            <BT-Number-Edit
                                                v-model.number="requestQTY"
                                                singleLine />
                                        </template>
                                    </v-edit-dialog>
                                    <v-btn
                                        v-if="mode == 'procurement'"
                                        icon
                                        small
                                        title="Purchase"
                                        @click.stop="openPurchaseOptions(item)">
                                        <v-icon small>mdi-cart</v-icon>
                                    </v-btn>
                                    <v-btn
                                        v-if="mode == 'management' && item.balance < item.upperThreshold && item.product.isManufactured"
                                        :class="item.displayBalance < 0 ? 'primary--text' : ''"
                                        small
                                        style="width: 24px; height: 24px;"
                                        icon
                                        @click.stop="manufacture(item)"
                                        title="Make">
                                        <v-icon small>mdi-factory</v-icon>
                                    </v-btn>
                                    <v-edit-dialog
                                        v-else-if="mode == 'management' && item.product.isManufactured"
                                        large
                                        saveText="Add To Batch"
                                        @save="manufacture(item, batchQTY)">
                                        <v-icon small style="width: 24px; height: 24px;">mdi-factory</v-icon>
                                        <template v-slot:input>
                                            <BT-Number-Edit
                                                v-model.number="batchQTY"
                                                singleLine />
                                        </template>
                                    </v-edit-dialog>
                                    <v-btn
                                        v-if="locationID != null"
                                        :buttonClass="item.displayBalance < 0 ? 'primary--text' : ''"
                                        icon
                                        small
                                        title="Transfer"
                                        @click.stop="openTransferOptions(item)">
                                        <v-icon small>mdi-transfer-right</v-icon>
                                    </v-btn>
                                    <v-btn
                                        v-if="locationID != null && currentBalanceItem === item"
                                        small
                                        icon
                                        title="Overview"
                                        @click.stop="openOverview(item)">
                                        <v-icon small>mdi-database-eye</v-icon>
                                    </v-btn>
                                    <v-btn
                                        v-if="locationID != null && currentBalanceItem === item"
                                        small
                                        icon
                                        title="Status"
                                        @click.stop="openStatus(item)">
                                        <v-icon small>mdi-database-clock</v-icon>
                                    </v-btn>
                                    <v-btn v-if="locationID != null && currentBalanceItem === item" small icon @click="showBatchesSidebar(item)" title="Batches">
                                        <v-icon small>mdi-cube</v-icon>
                                    </v-btn>
                                    <v-btn v-if="currentBalanceItem === item" small icon @click="showConsignmentsSidebar(item)" title="Consignments">
                                        <v-icon small>mdi-receipt</v-icon>
                                    </v-btn>
                                </v-row>
                            </template>
                            <template v-slot:settings="{ allItems }">
                                <v-divider />
                                <v-subheader>Balance Strategy</v-subheader>
                                <v-list-item @click="toggleStrategy(allItems)">
                                    <v-list-item-icon>
                                        <v-icon v-if="balancerStrat == 'Topup'" small>mdi-package-up</v-icon>
                                        <v-icon v-else-if="balancerStrat == 'Trigger'" small>mdi-gauge-low</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-if="balancerStrat == 'Topup'">Top-Up</v-list-item-title>
                                        <v-list-item-title v-else>Lower Threshold Trigger</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </BT-Blade-Items>
                    </v-tab-item>
                    <v-tab-item v-if="locationID != null">
                        <BT-Blades>
                            <template>
                                <BT-Blade-Item
                                    v-for="(transfer, index) in newTransfers" :key="index"
                                    :canDelete="false"
                                    :canRefresh="false"
                                    hideBackButton
                                    hideSaveAndClose
                                    ignorePermissions
                                    :item="transfer"
                                    navigation="stock-consignments"
                                    :onCanSave="canSaveTransfer"
                                    :onIsNew="item => { return item.id == null }"
                                    @saved="transferSaved"
                                    defaultPageWidth="350"
                                    title="Transfer"
                                    :actualUsedHeight="144">
                                    <template v-slot="{ item }">
                                        <BT-Field-Select
                                            v-model="item.departureLocationID"
                                            ignorePermissions
                                            navigation="locations"
                                            label="Departure Location"
                                            itemText="locationName" />

                                        <BT-Field-Select
                                            v-model="item.destinationLocationID"
                                            ignorePermissions
                                            navigation="locations"
                                            label="Destination Location"
                                            itemText="locationName" />

                                        <BT-Field-Date
                                            v-model="item.dueDate"
                                            label="Due Date"
                                            isEditing />

                                        <v-expansion-panels v-model="panelV">
                                            <BT-Expansion-Panel-List
                                                v-if="item != null"
                                                :items="item.consignmentOrderItems"
                                                label="Ordered Items">
                                                <template v-slot="{ item }">
                                                    <v-list-item-content>
                                                        <v-list-item-subtitle>
                                                            <span v-if="item.product != null">{{ item.product.productName }}</span>
                                                            <BT-Entity
                                                                v-else
                                                                navigation="public-products"
                                                                :itemValue="item.productID"
                                                                itemText="productName"
                                                                single
                                                                useLocalCache />
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-edit-dialog>
                                                            {{ item.quantity | toDisplayNumber }}
                                                            <template v-slot:input>
                                                                <BT-Field-Number
                                                                    v-model.number="item.quantity"
                                                                    dense
                                                                    isEditing />
                                                            </template>
                                                        </v-edit-dialog>
                                                    </v-list-item-action>
                                                </template>
                                            </BT-Expansion-Panel-List>
                                        </v-expansion-panels>
                                    </template>
                                </BT-Blade-Item>
                            </template>
                        </BT-Blades>
                    </v-tab-item>
                    <v-tab-item v-if="is3pl">
                        <BT-Blades>
                            <template>
                                <BT-Blade-Item
                                    v-for="(transfer, index) in new3plTransfers" :key="index"
                                    :canDelete="false"
                                    :canRefresh="false"
                                    hideBackButton
                                    hideSaveAndClose
                                    ignorePermissions
                                    :item="transfer"
                                    navigation="stock-imports"
                                    :onCanSave="item => { return item.isSaved ? 'Already Saved' : null; }"
                                    :onIsNew="item => { return item.id == null }"
                                    @saved="transferSaved"
                                    defaultPageWidth="350"
                                    title="3PL Transfer"
                                    :actualUsedHeight="144">
                                    <template v-slot="{ item }">
                                        <v-list-item v-if="item.sellerID != null" dense>
                                            <v-list-item-avatar>
                                                <v-img :src="companyLogoURL(item.sellerID)" class="mx-auto">
                                                    <template v-slot:placeholder>
                                                        <v-icon color="primary" >mdi-account</v-icon>
                                                    </template>
                                                </v-img>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-subtitle>3PL Client</v-list-item-subtitle>
                                                <v-list-item-title>
                                                    <BT-Entity
                                                        navigation="public-companies"
                                                        :itemValue="item.sellerID"
                                                        itemText="companyName"
                                                        single
                                                        useLocalCache />
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <BT-Field-Select
                                            v-model="item.destinationLocationID"
                                            ignorePermissions
                                            navigation="locations"
                                            label="Destination Location"
                                            itemText="locationName" />

                                        <v-expansion-panels v-model="panelV">
                                            <BT-Expansion-Panel-List
                                                v-if="item != null"
                                                :items="item.consignmentOrderItems"
                                                label="Ordered Items">
                                                <template v-slot="{ item }">
                                                    <v-list-item-content>
                                                        <v-list-item-subtitle>
                                                            <span v-if="item.product != null">{{ item.product.productName }}</span>
                                                            <BT-Entity
                                                                v-else
                                                                navigation="public-products"
                                                                :itemValue="item.productID"
                                                                itemText="productName"
                                                                single
                                                                useLocalCache />
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-edit-dialog>
                                                            {{ item.quantity | toDisplayNumber }}
                                                            <template v-slot:input>
                                                                <BT-Field-Number
                                                                    v-model.number="item.quantity"
                                                                    dense
                                                                    isEditing />
                                                            </template>
                                                        </v-edit-dialog>
                                                    </v-list-item-action>
                                                </template>
                                            </BT-Expansion-Panel-List>
                                        </v-expansion-panels>
                                    </template>
                                </BT-Blade-Item>
                            </template>
                        </BT-Blades>
                    </v-tab-item>
                    <v-tab-item v-if="mode == 'management'">
                        <BT-Blade-Items
                            label="Batches"
                            canBreakdown
                            :canClose="false"
                            :canRefresh="false"
                            :canSearch="false"
                            canSearchLocal
                            :canSelect="false"
                            hideBladeHeader
                            :items="newBatches"
                            :headers="[
                                { text: 'Product', value: 'product.productName', searchable: true },
                                { text: 'Location', value: 'locationID', navigation: 'locations', itemText: 'locationName' },
                                { text: 'Quantity', value: 'quantityGoal', display: true, },
                                { text: 'Batchcode', value: 'batchcode', display: true },
                                { text: 'Manufacture Date', value: 'dom', display: true },
                                { text: 'Expiry Date', value: 'exp', display: true }]"
                            :actualUsedHeight="144">
                            <template v-slot:quantityGoal="{ item }">
                                <v-edit-dialog>
                                    {{ item.quantityGoal | toDisplayNumber }}
                                    <template v-slot:input>
                                        <BT-Field-Number
                                            v-model="item.quantityGoal"
                                            :isEditing="!item.isSaved" />
                                    </template>
                                </v-edit-dialog>
                            </template>
                            <template slot="batchcode" slot-scope="{ item }">
                                <v-edit-dialog>
                                    {{ item.batchcode }}
                                    <template v-slot:input>
                                        <BT-Field-String
                                            v-model="item.batchcode"
                                            :isEditing="!item.isSaved" />
                                    </template>
                                </v-edit-dialog>
                            </template>
                            <template slot="dom" slot-scope="{ item }">
                                <v-edit-dialog>
                                    {{ item.dom | toShortDate }}
                                    <template v-slot:input>
                                        <BT-Field-Date
                                            v-model="item.dom"
                                            :isEditing="!item.isSaved" />
                                    </template>
                                </v-edit-dialog>
                            </template>
                            <template slot="exp" slot-scope="{ item }">
                                <v-edit-dialog>
                                    {{ item.exp | toShortDate }}
                                    <template v-slot:input>
                                        <BT-Field-Date
                                            v-model="item.exp"
                                            :isEditing="!item.isSaved" />
                                    </template>
                                </v-edit-dialog>
                            </template>
                            <template v-slot:itemActions="{ item }">
                                <v-btn
                                    v-if="!item.isSaved"
                                    :loading="item.loadingCount > 0"
                                    icon
                                    small
                                    @click="saveBatch(item)">
                                    <v-icon small>mdi-content-save</v-icon>
                                </v-btn>
                                <div v-else>Done</div>
                            </template>
                        </BT-Blade-Items>
                    </v-tab-item>
                    <v-tab-item v-if="mode == 'procurement'">
                        <v-row no-gutters>
                            <v-col v-for="(po, index) in newPurchaseOrders" :key="index + po.supplyAgreementID">
                                <Purchase-Order-Blade
                                    :purchaseOrder="po"
                                    :refreshToggle="po.refreshToggle"
                                    width="500"
                                    @saved="savedPO" />
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
                <BT-Snack v-model="msg" />
            </div>
        </template>
    </BT-Blade-Items>
</template>

<script>
import { getTetheredAmount } from '~helpers';

export default {
    name: 'Stock-Balancer-Hub',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue'),
        BTSidebarItems: () => import('~components/BT-Sidebar-Items.vue'),
        BTStockBalanceOverview: () => import('~components/BT-Stock-Balance-Overview.vue'),
        BTStockStatusOverview: () => import('~components/BT-Stock-Status-Overview.vue'),
        OrderLineItem: () => import('~home/purchase-orders/Ordering-Line-Item.vue'),
        PurchaseOrderBlade: () => import('~home/purchase-orders/Purchase-Order-Blade.vue')
    },
    data: function() {
        return {
            balancerItems: [],
            balancerStrat: 'Topup', //Trigger
            batches: null,
            batchLocationID: null,
            batchParams: null,
            batchToggle: false,
            batchQTY: 0,
            calcOnHand: true,
            calcOnOrder: true,
            calcToMake: true,
            calcIncoming: true,
            calcPurchasing: true,
            consignmentParams: null,
            consignmentToggle: false,
            currentBalanceItem: null,
            currentBalancerItem: null,

            currentPurchaseOptions: null,
            currentTransferOptions: null,

            defaultDOM: null,
            destinationLocationID: null,
            headers: null,
            locationID: null,
            locationSelected: false,
            mode: null, //procurement, management, 3pl
            msg: null,
            newBatches: [],
            newPurchaseOrders: [],
            new3plTransfers: [],
            newTransfers: [],

            openPurchaseToggle: false,
            openTransferToggle: false,

            panelV: 0,
            pendingImports: null,
            products: null,
            refreshToggle: false,
            requestLocationID: null,
            requestQTY: 0,
            showBatches: false,
            showConsignments: false,
            // showInDetail: true,
            showOverviewToggle: false,
            showStatusToggle: false,
            stepperState: 0,
            stockItems: null,
            tethers: null,
            thresholdAnchor: 'upper',
            thresholds: null,
            trackedItems: null,
            transfers: null
        }
    },
    async mounted() {
        if (this.$isWithinSubscription('SUPP')) {
            await this.selectMode({ mode: this.$route.params.mode || this.$route.query.mode });
        }
        else if (this.$isWithinSubscription('CUST')) {
            await this.selectMode({ mode: 'procurement' });
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        }
    },
    computed: {
        is3pl() {
            return this.mode == '3pl';
        },
        productParams() {
            if (this.mode == '3pl') {
                return {
                    includeDetails: false
                }
            }
            else if (this.mode == 'management') {
                return {
                    includeDetails: false,
                    filterBy: 'Manufactured'
                }
            }
            else if (this.mode == 'procurement') {
                return {
                    includeDetails: false,
                    filterBy: 'Acquired'
                }
            }

            return {
                includeDetails: false
            }
        }
    },
    methods: {
        filterTransferLocations(list) {
            return list.filter(y => y.id != this.locationID);
        },
        canSaveTransfer(transfer) {
            if (!transfer.dueDate) {
                return 'Select Due Date';
            }

            if (transfer.isSaved) {
                return 'Already Saved';
            }

            return null;
        },
        getBalancedItems(locationID) {
            var e = this.products.map(product => {
                var balancerItem = {
                    balance: 0,
                    displayBalance: 0,
                    incoming: 0,
                    onHand: 0,
                    onOrder: 0,
                    percent: 0,
                    product: product,
                    productID: product.id,
                    purchasing: 0,
                    toMake: 0,

                    lowerThreshold: 0,
                    upperThreshold: 0,

                    topUpAdjustment: 0,
                    // topUpBalance: 0,
                    triggerAdjustment: 0,
                    // triggerBalance: 0,

                    assigned: 0,
                    stockLevel: 0
                }

                //adjust if there are items that this product is tethered to
                var tethers = this.isLengthyArray(this.tethers) ? this.tethers.filter(z => z.tetheredProductID == product.id) : [];
                var triggeringTrackedItems = this.trackedItems.filter(y => tethers.some(tether => tether.triggeringProductID == y.productID));
                if (this.isLengthyArray(triggeringTrackedItems)) {
                    triggeringTrackedItems.forEach(triggeringItem => {
                        balancerItem.purchasing += getTetheredAmount(tethers, triggeringItem.onOrder, product.id, triggeringItem.productID);
                    })
                }

                //adjust what is onOrder and onHand
                var trackedProductItems = this.trackedItems.filter(y => y.productID == product.id);
                if (this.isLengthyArray(trackedProductItems)) {
                    trackedProductItems.forEach(tPItem => {
                        balancerItem.onOrder += tPItem.consigned;
                        balancerItem.onHand += tPItem.inStock;
                    })
                }

                //batch
                if (this.isLengthyArray(this.batches)) {
                    this.batches.filter(y => y.productID == product.id).forEach(x => {
                        balancerItem.toMake += x.quantityGoal;
                    })
                }

                //transfers
                if (this.isLengthyArray(this.transfers)) {
                    this.transfers.forEach(transfer => {
                        if (locationID == null) {
                            //no need to adjust 'incoming'
                            if (transfer.isSellerResolved) {
                                //no longer onOrder or onHand
                                if (!transfer.isBuyerResolved) {
                                    //then need to add to 'onHand', because the stock has left but not arrived
                                    var tEntry = transfer.consignmentEntryItems.find(y => y.productID == product.id);
                                    if (tEntry != null) {
                                        balancerItem.onHand += tEntry.quantity;
                                        balancerItem.stockLevel += tEntry.quantity;
                                    }
                                }
                            }
                            else {
                                //is still onOrder and onHand
                                var transferItem = transfer.consignmentOrderItems.find(y => y.productID == product.id);
                                if (transferItem != null) {
                                    //so remove from onOrder
                                    balancerItem.onOrder -= transferItem.quantity;
                                }

                                if (transfer.isBuyerResolved) {
                                    //remove from assigned, add to onHand and stockLevel
                                    var cEntry = transfer.consignmentEntryItems.find(z => z.productID == product.id);
                                    if (cEntry != null) {
                                        balancerItem.assigned -= cEntry.quantity;
                                        balancerItem.onHand += cEntry.quantity;
                                        balancerItem.stockLevel += cEntry.quantity;
                                    }
                                }
                            }
                        }
                        else {
                            if (transfer.destinationLocationID == locationID) {
                                //incoming transfer
                                if (!transfer.isBuyerResolved) {
                                    var cItem = transfer.consignmentOrderItems.find(y => y.productID == product.id);
                                    if (cItem != null) {
                                        balancerItem.incoming += cItem.quantity;
                                    }
                                }
                            }
                        }
                    });
                }

                //pending imports
                if (this.isLengthyArray(this.pendingImports)) {
                    //update purchasing from what's on order
                    this.pendingImports.forEach(pImport => {
                        if (locationID == null && pImport.isTransfer && pImport.isSellerResolved && !pImport.isBuyerResolved) {
                            console.log('import is resolved');
                            var pIEntry = pImport.consignmentEntryItems.find(y => y.productID == product.id);
                            if (pIEntry != null) {
                                balancerItem.onHand += pIEntry.quantity;
                                balancerItem.stockLevel += pIEntry.quantity;
                            }
                        }

                        //if is transfer and is coming from another company then won't be in transfers so address here
                        if (locationID != null) {
                            if (pImport.isTransfer) {
                                if (pImport.buyerID != pImport.sellerID) {
                                    if (pImport.destinationLocationID == locationID) {
                                        if (!pImport.isBuyerResolved) {
                                            var cItem = pImport.consignmentOrderItems.find(y => y.productID == product.id);
                                            if (cItem != null) {
                                                balancerItem.incoming += cItem.quantity;
                                            }
                                        }
                                    }
                                }
                            }
                            else {
                                pImport.consignmentOrderItems.forEach(cOrderItem => {
                                    var qt = 0;
                                    //not needed because it's already tracked
                                    if (cOrderItem.productID == product.id) {
                                        qt = cOrderItem.quantity;
                                        // balancerItem.purchasing += cOrderItem.quantity;
                                    }

                                    var importTethers = tethers.filter(t => t.triggeringProductID == cOrderItem.productID);
                                    qt += getTetheredAmount(importTethers, cOrderItem.quantity, product.id, cOrderItem.productID);
                                    // balancerItem.purchasing += getTetheredAmount(importTethers, cOrderItem.quantity, product.id, cOrderItem.productID);

                                    if (pImport.isBuyerResolved) {
                                        //remove from purchasing
                                        balancerItem.purchasing -= qt;
                                        if (!pImport.isSellerResolved) {
                                            // balancerItem.onHand += qt;
                                        }
                                    }
                                    else if (pImport.isSellerResolved) {
                                        // adjust what's incoming
                                        balancerItem.purchasing += qt;
                                    }
                                });
                            }
                        }
                    });
                }

                balancerItem.stockLevel = balancerItem.stockLevel + this.stockItems.filter(z => z.productID == product.id).sum(z => z.available);
                balancerItem.assigned = balancerItem.assigned + balancerItem.onHand - balancerItem.stockLevel;

                //balance
                if (this.calcOnOrder) {
                    balancerItem.balance -= balancerItem.onOrder;
                }

                if (this.calcOnHand) {
                    balancerItem.balance += balancerItem.onHand;
                }

                if (this.calcPurchasing) {
                    balancerItem.balance += balancerItem.purchasing;
                }

                if (this.calcIncoming) {
                    balancerItem.balance += balancerItem.incoming;
                }

                if (this.calcToMake) {
                    balancerItem.balance += balancerItem.toMake;
                }

                var thresholds = this.thresholds.filter(x => x.productID == balancerItem.product.id);
                if (this.isLengthyArray(thresholds)) {
                    balancerItem.lowerThreshold = thresholds.sum(z => z.lowerThreshold);
                    balancerItem.upperThreshold = thresholds.sum(z => z.upperThreshold);
                }

                if (balancerItem.balance <= balancerItem.upperThreshold) {
                    balancerItem.topUpAdjustment = balancerItem.upperThreshold - balancerItem.balance;
                }

                if (balancerItem.balance <= balancerItem.lowerThreshold) {
                    balancerItem.triggerAdjustment = balancerItem.upperThreshold - balancerItem.balance;
                }

                this.updateThresholdPercent(balancerItem);

                return balancerItem;
            });

            return e;
        },
        getHeaders(mode) {
            var r = [];

            if (!this.is3pl) {
                r.push({ text: 'Code', value: 'product.abbreviation', searchable: true, subtitle: 1 });
            }
            
            r.push({ text: 'Product', value: 'product.productName', display: true, searchable: true, title: 1 });
            r.push({ text: 'Customer Orders', value: 'onOrder', display: true, textFilter: 'toDisplayNumberOver', class: 'error--text', breakdown: true });

            // if (mode == '3pl') {

            // }
            if (mode == 'management' || mode == '3pl') {
                r.push({ text: 'Assigned', value: 'assigned', display: true, textFilter: 'toDisplayNumberOver', class: 'success--text', breakdown: true });
                r.push({ text: 'In Stock', value: 'stockLevel', display: true, textFilter: 'toDisplayNumberOver', class: 'success--text', breakdown: true });
                r.push({ text: 'Incoming', value: 'incoming', display: true, textFilter: 'toDisplayNumberOver', class: 'success--text', breakdown: true });
                r.push({ text: '%', value: 'percent', display: true, hide: false });
                r.push({ text: 'Threshold Adjustments', value: 'threshold', display: true, textFilter: 'toDisplayNumberSigned', breakdown: true, hide: false });
                // r.push({ text: 'Threshold', value: 'displayThreshold', display: true, breakdown: true, hide: false });
                // r.push({ text: 'Threshold Adjustments', value: 'threshold', display: true, textFilter: 'toDisplayNumberSigned', breakdown: true, hide: false });
            }
            else if (mode == 'procurement') {
                r.push({ text: 'On Hand', value: 'onHand', display: true, textFilter: 'toDisplayNumberOver', class: 'success--text', breakdown: true });
                r.push({ text: 'Incoming', value: 'incoming', display: true, textFilter: 'toDisplayNumberOver', class: 'success--text', breakdown: true });
                r.push({ text: '%', value: 'percent', display: true, hide: false });
                r.push({ text: 'Threshold Adjustments', value: 'threshold', display: true, textFilter: 'toDisplayNumberSigned', breakdown: true, hide: false });
            }

            r.push({
                text: 'Balance',
                value: 'displayBalance',
                display: true,
                breakdown: true,
                prefix: 'Balance: ',
                subtitle: 1
            });

            r.push({ text: 'Actions', value: 'options', display: true });

            return r;
        },
        selectBalanceItem(b) {
            if (b != null) {
                this.currentBalanceItem = b;
                this.consignmentParams = b != null ? {
                    departureLocationID: this.locationID,
                    orderProductID: b.productID,
                    stockProductID: b.productID } : null;

                this.batchParams = b != null ? {
                    locationID: this.locationID,
                    productID: b.productID
                } : null;

                if (this.showBatches) {
                    this.batchToggle = !this.batchToggle;
                }

                if (this.showConsignments) {
                    this.consignmentToggle = !this.consignmentToggle;
                }
            }
        },
        async selectMode(m) {
            this.mode = m != null ? m.mode : null;

            if (this.mode != null) {
                if (this.mode == 'procurement') {
                    this.balancerStrat = 'Trigger';
                }
                else {
                    this.balancerStrat = 'Topup';
                }

                this.headers = this.getHeaders(this.mode);

                try {
                    if (this.locationID == null) {
                        this.locationID = this.selectedLocationID();

                        if (this.locationID == null) {
                                this.locationID = await this.$selectItem({
                                    navigation: 'locations',
                                    itemText: 'locationName',
                                    itemValue: 'id',
                                });
                            }
                        }
                    }
                    finally {
                        this.locationSelected = true;
                        this.batchLocationID = this.locationID;
                        this.requestLocationID = this.locationID;
                        this.destinationLocationID = this.locationID;
                    }
            }
        },
        openOverview(item) {
            this.currentBalanceItem = item;
            this.showOverviewToggle = !this.showOverviewToggle;
        },
        openStatus() {
            this.showStatusToggle = !this.showStatusToggle;
        },
        openPurchaseOptions(balancerItem) {
            var tethers = this.tethers.filter(z => z.tetheredProductID == balancerItem.productID);
            var needs = balancerItem.upperThreshold - balancerItem.balance;
            if (needs < 0) {
                needs = 0;
            }
            
            this.currentPurchaseOptions = {
                balancerItem: balancerItem,
                locationID: this.destinationLocationID,
                orderItems: tethers.map(x => {
                    var rules = [];

                    if (x.orderInIncrements > 0) {
                        rules.push({ rule: 'QuantityInIncrements', quantity: x.orderInIncrements });
                    }

                    if (x.minimumOrderQuantity > 0) {
                        rules.push({ rule: 'QuantityMoreThan', quantity: x.minimumOrderQuantity });
                    }

                    var qty = 0;
                    var oldQty = 0;

                    if (needs > 0 && x.orderInIncrements > 0) {
                        console.log('dif: ' + needs % x.orderInIncrements);
                        qty = needs - (needs % x.orderInIncrements) + x.orderInIncrements;
                        needs = 0;
                    }

                    var existPO = this.newPurchaseOrders.find(z => z.sellerID == x.triggeringProduct.companyAccountID);
                    if (existPO != null) {
                        var existingItem = existPO.orderItems.find(o => o.productID == x.triggeringProductID);
                        if (existingItem != null) {
                            qty = existingItem.quantity;
                            oldQty = qty;
                        }
                    }

                    return {
                        unitPrice: 0,
                        amountTotal: 0,
                        quantity: qty,
                        oldQuantity: oldQty,
                        product: x.triggeringProduct,
                        productID: x.triggeringProductID,
                        description: x.triggeringProduct.productName,
                        data: {
                            rules: rules
                        }
                    };
                })
            };

            this.openPurchaseToggle = !this.openPurchaseToggle;
        },
        openTransferOptions(balancerItem) {
            var needs = balancerItem.upperThreshold - balancerItem.balance;
            if (needs < 0) {
                needs = 0;
            }

            if (this.currentTransferOptions == null) {
                this.currentTransferOptions = {
                    isTo: true,
                    quantity: needs,
                    locationID: null,
                    product: balancerItem.product,
                    productID: balancerItem.product.id,
                    balancerItem: balancerItem
                }
            }

            this.currentTransferOptions.product = balancerItem.product;
            this.currentTransferOptions.productID = balancerItem.productID;
            this.currentTransferOptions.balancerItem = balancerItem;

            if (needs > 0) {
                this.currentTransferOptions.quantity = needs;
                this.currentTransferOptions.isTo = false;
            }
            else {
                this.currentTransferOptions.quantity = 0 - needs;
                this.currentTransferOptions.isTo = true;
            }

            if (this.locationID == this.currentTransferOptions.locationID) {
                this.currentTransferOptions.locationID = null;
            }

            if (this.currentTransferOptions.locationID != null) {
                this.transfer(this.currentTransferOptions);
            }
            else {
                this.openTransferToggle = !this.openTransferToggle;
            }
        },
        async manufacture(balancerItem, customQTY = null) {
            if (this.defaultDOM == null) {
                try {
                    this.defaultDOM = await this.$selectDate({ 
                        label: 'Select DOM', 
                        originalValue: this.$BlitzIt.auth.getTomorrow() 
                    });
                }
                catch {
                    this.msg = "Please Select a DOM";
                }
            }

            if (this.batchLocationID == null) {
                try {
                    this.batchLocationID = await this.$selectItem({
                        navigation: 'locations',
                        itemText: 'locationName',
                        itemValue: 'id',
                        required: true
                    });
                }
                catch {
                    this.msg = 'Please select a location for manufacture';
                    return;
                }
            }

            if (this.defaultDOM == null) {
                this.msg = "Please Select a Date Of Manufacture";
            }
            else {
                var qty = 0 - balancerItem.balance;

                if (customQTY != null) {
                    qty = customQTY >= 0 ? customQTY : 0 - customQTY;
                }
                else if (this.balancerStrat == 'Topup') {
                    qty = balancerItem.topUpAdjustment;
                }
                else if (this.balancerStrat == 'Trigger') {
                    qty = balancerItem.triggerAdjustment;
                }
                
                if (qty > 0) {
                    var existingBatch = this.newBatches.find(y => y.productID == balancerItem.product.id);
                    if (existingBatch == null) {
                        var prod = balancerItem.product;
                        existingBatch = {
                            productID: prod.id,
                            product: prod,
                            quantity: 0,
                            quantityGoal: 0,
                            dom: this.defaultDOM,
                            exp: prod.expiryDays > 0 ? this.$BlitzIt.auth.formUTC(this.defaultDOM, null, prod.expiryDays) : null,
                            batchcode: prod.batchcodeFormat != null ? this.$BlitzIt.auth.formTZ(this.defaultDOM, prod.batchcodeFormat) : null,
                            onHold: false,
                            isPending: true,
                            locationID: this.batchLocationID,
                            isSaved: false
                        }

                        this.newBatches.push(existingBatch);
                    }

                    existingBatch.quantityGoal += qty;

                    if (this.calcToMake) {
                        balancerItem.balance += qty;
                    }

                    balancerItem.toMake += qty;

                    this.updateThresholdPercent(balancerItem);
                }
            }
        },
        async pullAssignments(consignments, balanceItem) {
            console.log(consignments);
            console.log(balanceItem);
            
            return consignments.map(z => {
                var oItems = z.consignmentOrderItems.filter(oItem => oItem.productID == balanceItem.productID);
                var sItems = z.consignmentEntryItems.filter(eItem => eItem.productID == balanceItem.productID);
                Object.assign(z, { orderQuantity: oItems.sum(z => z.quantity), stockQuantity: sItems.sum(z => z.quantity) });
                return z;
            })
        },
        async purchase(options) {
            var oItems = options.orderItems.filter(z => z.quantity > 0 || z.oldQuantity > 0);

            if (options.locationID != null) {
                this.destinationLocationID = options.locationID;
            }

            for (let i = 0; i < oItems.length; i++) {
                const oItem = oItems[i];
                var existingOrder = this.newPurchaseOrders.find(p => p.sellerID == oItem.product.companyAccountID);
                if (existingOrder == null) {
                    var sellerID = oItem.product.companyAccountID;
                    var supp = await this.$BlitzIt.store.get('suppliers', sellerID, { includeDetails: false, supplierID: sellerID }, true, null, null);

                    if (supp != null) {
                        existingOrder = {
                            dueDate: null,
                            isBuyerCourier: null,
                            isRequestPickup: false,
                            locationID: options.locationID,
                            refreshToggle: false,
                            requestedDepartureLocationID: null,
                            orderItems: [{
                                product: oItem.product,
                                productID: oItem.product.id,
                                quantity: oItem.quantity
                            }],
                            sellerID: supp.sellerID,
                            seller: supp.seller,
                            standingOrderID: null,
                            supplyAgreementID: supp.id
                        }

                        this.newPurchaseOrders.push(existingOrder);
                    }
                }
                else {
                    var existingOItem = existingOrder.orderItems.find(o => o.productID == oItem.productID);
                    if (existingOItem == null) {
                        existingOItem = {
                            product: oItem.product,
                            productID: oItem.product.id,
                            quantity: 0
                        };

                        existingOrder.orderItems.push(existingOItem);
                    }

                    existingOItem.quantity = oItem.quantity;

                    existingOrder.refreshToggle = !existingOrder.refreshToggle;
                }

                var dif = oItem.quantity - oItem.oldQuantity;

                options.balancerItem.balance += dif;
                options.balancerItem.displayBalance += dif;
                options.balancerItem.purchasing += dif;
            }

            this.updateThresholdPercent(options.balancerItem);
        },
        async refreshSources(products, refresh, bladeData) {
            var locationID = this.locationID || bladeData.data.locationID;

            var productsToShow = products;
            if (this.is3pl) {
                productsToShow = [];
            }

            if (refresh || this.trackedItems == null) {
                this.trackedItems = await this.$BlitzIt.store.getAll('stock-tracked-items', { locationID: locationID }, refresh);

                if (this.is3pl) {
                    //add products that are not my own
                    productsToShow.push(...this.trackedItems.map(x => x.productID).filter(z => !products.some(a => a.id == z)));
                }
            }
            if (refresh || this.thresholds == null) {
                this.thresholds = await this.$BlitzIt.store.getAll('stock-thresholds', { locationID: locationID }, refresh);

                if (this.is3pl) {
                    //add products that are not my own
                    productsToShow.push(...this.thresholds.map(x => x.productID).filter(z => !products.some(a => a.id == z)));
                }
            }
            if (refresh || this.batches == null) {
                if (!this.is3pl && products.some(p => p.isManufactured)) {
                    this.batches = await this.$BlitzIt.store.getAll('batches', { locationID: locationID, filterBy: 'Pending' }, refresh);
                }
            }
            if (refresh || this.transfers == null) {
                this.transfers = await this.$BlitzIt.store.getAll('stock-consignments', { includeDetails: true, filterBy: 'Transfers', destinationLocationID: locationID }, refresh);
            }
            if (refresh || this.tethers == null) {
                // if (this.mode == 'procurement') {
                    this.tethers = await this.$BlitzIt.store.getAll('product-tethers', { includeDetails: false, isAcquired: true }, refresh);
                // }
            }
            if (refresh || this.stockItems == null) {
                this.stockItems = await this.$BlitzIt.store.getAll('stock-items', { locationID: locationID }, refresh);

                if (this.is3pl) {
                    //add products that are not my own
                    productsToShow.push(...this.stockItems.map(x => x.productID).filter(z => !products.some(a => a.id == z)));
                }
            }
            if (refresh || this.pendingImports == null) {
                // if (this.mode == 'procurement') {
                this.pendingImports = await this.$BlitzIt.store.getAll('stock-imports', { includeDetails: true, locationID: locationID, filterBy: 'All' });

                if (this.is3pl) {
                    this.pendingImports.forEach(ext => {
                        ext.consignmentOrderItems.forEach(oItem => {
                            if (!productsToShow.some(id => id == oItem.productID) && oItem.product.companyAccountID != ext.buyerID) {
                                productsToShow.push(oItem.productID);
                            }
                        })
                    })
                }
                // }
            }

            // this.products = products;
            if (this.is3pl) {
                if (this.isLengthyArray(productsToShow)) {
                    this.products = await this.$BlitzIt.store.getAll('public-products', { ids: productsToShow.toString()})
                }
                else {
                    this.products = [];
                }
            }
            else {
                this.products = products;
            }

            return this.getBalancedItems(locationID);

        },
        async requestStock(balancerItem, customQTY = null) {
            if (this.requestLocationID == null) {
                try {
                    this.requestLocationID = await this.$selectItem({
                        navigation: 'locations',
                        itemText: 'locationName',
                        itemValue: 'id',
                        required: true
                    });
                }
                catch {
                    this.msg = 'Please select a location';
                    return;
                }
            }

            var qty = 0 - balancerItem.balance;

            if (customQTY != null) {
                qty = customQTY >= 0 ? customQTY : 0 - customQTY;
            }
            else if (this.balancerStrat == 'Topup') {
                qty = balancerItem.topUpAdjustment;
            }
            else if (this.balancerStrat == 'Trigger') {
                qty = balancerItem.triggerAdjustment;
            }

            if (qty > 0) {
                var existingTransfer = this.new3plTransfers.find(z => z.sellerID == balancerItem.product.companyAccountID);

                if (existingTransfer == null) {
                    existingTransfer = {
                        id: null,
                        departureLocationID: null,
                        destinationLocationID: this.requestLocationID,
                        consignmentOrderItems: [],
                        dueDate: this.defaultDOM,
                        isSaved: false,
                        sellerID: balancerItem.product.companyAccountID
                    }

                    this.new3plTransfers.push(existingTransfer);
                }

                balancerItem.balance += qty;
                balancerItem.incoming += qty;

                this.updateThresholdPercent(balancerItem);

                var transferItem = existingTransfer.consignmentOrderItems.find(y => y.productID == balancerItem.productID);
                if (transferItem == null) {
                    transferItem = {
                        product: balancerItem.product,
                        productID: balancerItem.productID,
                        quantity: 0
                    }
                    existingTransfer.consignmentOrderItems.push(transferItem);
                }

                transferItem.quantity += qty;
            }

        },
        reset() {
            this.balancerItems = [];
            this.batches = null;
            this.newBatches = [];
            this.newPurchaseOrders = [];
            this.newTransfers = [];
            this.products = null;
            this.thresholds = null;
            this.trackedItems = null;
            this.transfers = null;
        },
        restart() {
            this.reset();
            this.stepperState = 0;
            this.refreshToggle = !this.refreshToggle;
        },
        async saveBatch(batch) {
            try {
                batch.loadingCount += 1;
                this.$forceUpdate();

                await this.$BlitzIt.store.post('batches', batch);
                batch.isSaved = true;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                batch.loadingCount -= 1;
            }
        },
        savedPO(po) {
            var poInd = this.newPurchaseOrders.findIndex(z => z.supplyAgreementID == po.supplyAgreementID);
            if (poInd > -1) {
                this.newPurchaseOrders.splice(poInd, 1);
            }
        },
        showBatchesSidebar(bItem) {
            this.batchToggle = !this.batchToggle;
            this.showConsignments = false;
            this.showBatches = true;
            this.selectBalanceItem(bItem);
        },
        showConsignmentsSidebar(bItem) {
            this.consignmentToggle = !this.consignmentToggle;
            this.showBatches = false;
            this.showConsignments = true;
            this.selectBalanceItem(bItem);
        },
        toggleStrategy(allItems) {
            this.balancerStrat = this.balancerStrat == 'Topup' ? 'Trigger' : 'Topup';

            allItems.forEach(e => {
                this.updateThresholdPercent(e);
            })
        },
        transferSaved(transfer) {
            transfer.isSaved = true;
        },
        transfer(options) {
            if (options.locationID != null && options.locationID != this.locationID && options.quantity > 0) {
                var qty = options.quantity;
                var transfer = null;
                if (options.isTo) {
                    transfer = this.newTransfers.find(y => y.destinationLocationID == options.locationID);
                    if (transfer == null) {
                        transfer = {
                            departureLocationID: this.locationID,
                            destinationLocationID: options.locationID,
                            consignmentOrderItems: [],
                            dueDate: this.defaultDOM,
                            isSaved: false
                        }

                        this.newTransfers.push(transfer);
                    }

                    options.balancerItem.balance -= qty;
                    options.balancerItem.onOrder += qty;
                }
                else {
                    transfer = this.newTransfers.find(y => y.departureLocationID == options.locationID);
                    if (transfer == null) {
                        transfer = {
                            destinationLocationID: this.locationID,
                            departureLocationID: options.locationID,
                            consignmentOrderItems: [],
                            dueDate: this.defaultDOM,
                            isSaved: false
                        }

                        this.newTransfers.push(transfer);
                    }

                    options.balancerItem.balance += qty;
                    options.balancerItem.incoming += qty;
                }

                this.updateThresholdPercent(options.balancerItem);

                var transferItem = transfer.consignmentOrderItems.find(y => y.productID == options.product.id);
                if (transferItem == null) {
                    transferItem = {
                        product: options.product,
                        productID: options.product.id,
                        quantity: 0
                    }
                    transfer.consignmentOrderItems.push(transferItem);
                }

                transferItem.quantity += qty;
            }
        },
        updateThresholdPercent(balancerItem) {
            //threshold
            if (balancerItem.upperThreshold > 0) {
                balancerItem.percent = Math.round(this.percent(balancerItem.balance, balancerItem.upperThreshold));
            }

            if (this.balancerStrat == 'Topup') {
                var dif = balancerItem.upperThreshold - balancerItem.balance;
                // if (dif > 0) {
                    balancerItem.displayBalance = 0 - dif;
                // }
                // else {
                //     balancerItem.displayBalance = 0;
                // }
            }
            else if (this.balancerStrat == 'Trigger') {
                if (balancerItem.balance <= balancerItem.lowerThreshold) {
                    balancerItem.displayBalance = 0 - (balancerItem.upperThreshold - balancerItem.balance);
                }
                else if (balancerItem.balance >= balancerItem.upperThreshold) {
                    balancerItem.displayBalance = 0 - (balancerItem.upperThreshold - balancerItem.balance);
                }
                else {
                    balancerItem.displayBalance = 0;
                }
            }
        }
    }
}
</script>